import { Listbox, Menu } from '@headlessui/react'
import {
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  EyeIcon,
  EyeSlashIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { PaginationController, Button, ExportTableDataModal } from '@sistemiv/s-components'
import { FieldT } from '@sistemiv/s-components/dist/esm/components/table-fields-filter/TableFieldsFilter'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useMemo, useState } from 'react'
import CreateProcessInstanceModal from './create-process-instance/CreateProcessInstanceModal'
import { Link, useParams } from 'react-router-dom'
import { useCurrentProcessDefinition } from '../../repositories/process-instances/process-definition-selected.repository'
import meterIcon from '../../assets/icons/meter.svg'
import { useTranslation } from 'react-i18next'
import { useBulkActions } from '../../repositories/process-instances/process-instances-actions.repository'
import { ProcessTableRow } from './table/TableProcess'
import TableFieldsFilter from './table/TableFieldsFilter'

type ActionScope = 'User task' | 'Service task' | 'Process instance'
export interface ProcesInstanceActions {
  id: string
  name: string
  scope: ActionScope
}

type ProcessInstanceHeaderProps = {
  total: number
  size: number
  page: number
  fields: FieldT[]
  onRestoreFields: () => void
  selectedFields: FieldT[]
  selectedRows: ProcessTableRow[]
  onSelectedFieldsChange?: (fields: FieldT[]) => void
  onPageChange?: (page: number) => void
  onSizeChange?: (size: number) => void
  onCreate?: (data: any) => Promise<any>
  onExport?: (data: any) => void
  onBulkActionClicked?: (action: string) => void
  onCompleteTasksClicked?: () => void
  showDoneTasks?: boolean
  setShowDoneTasks?: DispatchSetAction<boolean>
  showNotification?: boolean
  setShowNotification?: (value: boolean) => void
  onRefresh?: () => void
}
const ProcessInstanceHeader: FC<ProcessInstanceHeaderProps> = ({
  total,
  size,
  page,
  fields,
  selectedFields,
  selectedRows,
  showDoneTasks,
  setShowDoneTasks,
  onSelectedFieldsChange,
  onPageChange,
  onSizeChange,
  onExport,
  onRestoreFields,
  onBulkActionClicked,
  onCompleteTasksClicked,
  showNotification,
  setShowNotification,
  onRefresh,
}) => {
  const { org, type } = useParams()
  const [isCreateOpen, setCreateOpen] = useState(false)
  const [isExportOpen, setExportOpen] = useState(false)
  const { t } = useTranslation()
  const { data: currentProcess } = useCurrentProcessDefinition({ org, processDefinitionKey: type, versions: true })
  const { data: updateOptions } = useBulkActions(selectedRows?.map((row) => row['id'].value))
  const canCompleteTasks = !!updateOptions?.find((option) => option.id.includes('complete'))
  const processInstanceUpdateOptions =
    updateOptions && updateOptions.filter((action) => action.scope === 'Process instance')
  const userTaskUpdateOptions =
    updateOptions && updateOptions.filter((action) => action.scope === 'User task' && !action.id.includes('complete'))
  const serviceTaskUpdateOptions = updateOptions && updateOptions.filter((action) => action.scope === 'Service task')

  const notStartable = useMemo(() => currentProcess?.versions?.every((el) => el.startable === false), [currentProcess])

  return (
    <div className='flex items-center justify-between' data-testid='process-instance-header'>
      <div className='flex items-center gap-x-4'>
        <div className='flex items-center gap-x-4'>
          <div className='w-4 h-4 rounded-sm' style={{ backgroundColor: currentProcess?.color }}></div>
          <p className='whitespace-nowrap overflow-hidden text-ellipsis max-w-xs'>{currentProcess?.name}</p>
        </div>
        {!notStartable && (
          <div data-testid='create-button'>
            <Button
              className='border border-blue-500 text-blue-500 inline-flex gap-x-2 items-center'
              onClick={() => setCreateOpen(true)}
            >
              <PlusIcon className='w-5 h-5' />
              {t('Common.create')}
            </Button>
          </div>
        )}
        {total > 0 && (
          <div data-testid='export-button'>
            <Button className='border border-blue-500 text-blue-500' onClick={() => setExportOpen(true)}>
              {t('Common.export')}
            </Button>
          </div>
        )}
        {selectedRows.length > 0 && (
          <div>
            <Listbox
              onChange={(e) => onBulkActionClicked && onBulkActionClicked(e)}
              disabled={!!selectedRows?.find((row) => row['Resolution'].value !== '')}
            >
              <div className='relative focus:outline-none focus-visible:ring-0 sm:text-sm'>
                <Listbox.Button
                  className={`relative flex items-center gap-x-3 text-white ${
                    selectedRows?.find((row) => row['Resolution'].value !== '') ? 'bg-slate-400' : 'bg-blue-500'
                  }  rounded-md px-4 py-2 tex-sm`}
                >
                  <span> {t('Common.update')}</span>
                  <ChevronDownIcon className='w-4 h-4 text-white stroke-2 flex-none' />
                </Listbox.Button>
                <Listbox.Options className='absolute z-10 mt-1 rounded-md min-w-max max-w-sm bg-white drop-shadow-md shadow-md py-3 text-sm'>
                  {processInstanceUpdateOptions && processInstanceUpdateOptions?.length > 0 && (
                    <div>
                      <p className='px-4 py-1.5 text-xs text-gray-400'>{t('ProcessInstance.processInstance')}</p>
                      {processInstanceUpdateOptions?.map((option, index) => (
                        <Listbox.Option
                          key={`PIoption-${index}`}
                          value={option.id}
                          className='px-4 py-3 bg-white cursor-pointer hover:bg-blue-50'
                        >
                          {option.name}
                        </Listbox.Option>
                      ))}
                    </div>
                  )}
                  {userTaskUpdateOptions && userTaskUpdateOptions?.length > 0 && (
                    <div className='border-t border-gray-300'>
                      <p className='px-4 py-1.5 text-gray-400 text-xs'>{t('ProcessInstance.userTask')}</p>
                      {userTaskUpdateOptions?.map((option, index) => (
                        <Listbox.Option
                          key={`UToption-${index}`}
                          value={option.id}
                          className='px-4 py-3 bg-white cursor-pointer hover:bg-blue-50'
                        >
                          {option.name}
                        </Listbox.Option>
                      ))}
                    </div>
                  )}
                  {serviceTaskUpdateOptions && serviceTaskUpdateOptions?.length > 0 && (
                    <div className='border-t border-gray-300'>
                      <p className='px-4 py-1.5 text-gray-400 text-xs'>{t('ProcessInstance.serviceTask')}</p>
                      {serviceTaskUpdateOptions.map((option, index) => (
                        <Listbox.Option
                          key={`UToption-${index}`}
                          value={option.id}
                          className='px-4 py-3 bg-white cursor-pointer hover:bg-blue-50'
                        >
                          {option.name}
                        </Listbox.Option>
                      ))}
                    </div>
                  )}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
        )}
        {canCompleteTasks && (
          <Button className='bg-blue-500 text-white' onClick={() => onCompleteTasksClicked && onCompleteTasksClicked()}>
            {t('Common.complete')}
          </Button>
        )}
      </div>
      {showNotification && (
        <div
          className={`py-3 px-4 bg-[#E1F4FF] rounded-lg gap-x-2 shadow-md  transition-opacity duration-500 w-96 flex justify-between items-center text-blue-500`}
        >
          {/* <div className='flex items-center'> */}
          <span>New data available!</span>
          <Button
            onClick={onRefresh}
            className='border border-blue-500 ml-4 text-blue-500 inline-flex !py-1 items-center'
          >
            Refresh
          </Button>
          {/* </div> */}
          <Button
            className='text-blue-500 items-center !p-0'
            onClick={() => setShowNotification && setShowNotification(false)}
          >
            <XMarkIcon className='w-4 h-4' />
          </Button>
        </div>
      )}

      <div className='flex items-center gap-x-2 ml-1'>
        {total > 0 && (
          <div className='flex items-center'>
            <PaginationController
              total={total}
              size={size}
              page={page}
              onPageChange={onPageChange}
              onSizeChange={onSizeChange}
            />
            <Menu as='div' className='relative inline-block text-left'>
              <Menu.Button className='inline-flex justify-center w-full py-2' name='table-fields-filter-btn'>
                <div data-testid='table-fields-filter-btn'>
                  <AdjustmentsHorizontalIcon className='h-5 w-5 text-gray-900' />
                </div>
              </Menu.Button>
              <Menu.Items className='absolute right-0 mt-2 w-60 z-30'>
                <TableFieldsFilter
                  selected={selectedFields}
                  setSelected={(value) => onSelectedFieldsChange && onSelectedFieldsChange(value)}
                  fields={fields}
                  onRestoreDefault={onRestoreFields}
                />
              </Menu.Items>
            </Menu>
          </div>
        )}
        <Button className=' !gap-x-0' onClick={() => setShowDoneTasks && setShowDoneTasks(!showDoneTasks)}>
          {showDoneTasks ? <EyeIcon className='w-5 h-5' /> : <EyeSlashIcon className='w-5 h-5' />}
          {t('Common.done')}
        </Button>
        <div className='bg-slate-200 h-[35px] w-[1px]'></div>
        <Link to={`/${org}/process-instance-dashboard/${type}`} className='flex-none'>
          <img className='cursor-pointer' src={meterIcon} />
        </Link>
      </div>
      {isCreateOpen && <CreateProcessInstanceModal open={isCreateOpen} setOpen={setCreateOpen} />}
      {isExportOpen && (
        <ExportTableDataModal
          open={isExportOpen}
          setOpen={setExportOpen}
          onConfirm={(val) => {
            onExport && onExport(val)
            setExportOpen(false)
          }}
          selectableRows={true}
        />
      )}
    </div>
  )
}

export default ProcessInstanceHeader
