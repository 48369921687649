import React, { FC } from 'react'
import TaskItem, { TaskItemProps } from './TaskItem'
import classNames from '../../../classNames'
import { useResponsive } from '../../../context/ResponsiveContextProvider'

export type TaskListProps = {
  tasks: TaskItemProps[]
  onTaskSelected?: (id: string) => void
  selectedId: string
}
const TaskList: FC<TaskListProps> = ({ tasks, onTaskSelected, selectedId }) => {
  const { isMobile } = useResponsive()
  return (
    <div
      className={classNames('overflow-y-auto', !isMobile ? 'divide-y divide-gray-300' : 'pb-20')}
      data-testid='task-list'
    >
      {tasks.map((task, i) => {
        return <TaskItem key={i} {...task} onSelected={onTaskSelected} selected={selectedId === task.id} />
      })}
    </div>
  )
}

export default TaskList
