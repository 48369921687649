import React, { FC } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import PrivateRoute from './PrivateRoute'
import NotFound from '../pages/NotFound'
import NotAuthorized from '../pages/NotAuthorized'
import Logout from '../pages/Logout'
import ResponsiveContextProvider from '../context/ResponsiveContextProvider'

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <ResponsiveContextProvider>
        <Routes>
          <Route
            path='/'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path='/:org'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path='/:org/:nav'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path='/:org/:nav/:type'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path='/:org/:nav/:type/:id'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route path='/not-authorized' element={<NotAuthorized />} />
          <Route path='/not-found' element={<NotFound />} />
          <Route path='*' element={<Navigate to='/not-found' />} />
          <Route path='/logout' element={<Logout />} />
        </Routes>
      </ResponsiveContextProvider>
    </BrowserRouter>
  )
}

export default AppRouter
