import React, { FC, useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import ComboboxPillsWarning from './ComboboxPillsWarning'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import ModalDialog from '../../../../../ModalDialog'
import { Button, Input } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'

type NewSectionModalProps = {
  open: boolean
  name?: string
  newVariables?: boolean
  variables: any[]
  creating?: boolean
  setOpen: DispatchSetAction<boolean>
  onCancel?: () => void
  onCreate?: (sectionName: string, chosenVariables: any[]) => void
}

const NewSectionModal: FC<NewSectionModalProps> = ({
  open,
  name,
  newVariables,
  variables,
  creating,
  setOpen,
  onCancel,
  onCreate,
}) => {
  const [value, setValue] = useState(name ?? '')
  const [chosenVariables, setChosenVariables] = useState<any[]>([])
  const { t } = useTranslation()
  useEffect(() => {
    open && setValue(name ?? '')
  }, [open, name])

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex justify-between'>
        {newVariables
          ? t('Settings.variables.addNew')
          : name
          ? t('Settings.variables.addMore')
          : t('Settings.variables.newSection')}
        <button onClick={() => setOpen(false)}>
          <XMarkIcon className='w-5 h-5 stroke-gray-500' />
        </button>
      </Dialog.Title>

      <div>
        <div className='mt-5 space-y-6'>
          {!newVariables && (
            <Input
              id='name'
              disabled={!!name}
              placeholder={t('Settings.variables.name') as string}
              type='text'
              removePencil
              classes='!border-solid border border-gray-300 focus:border-sky-100'
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          )}

          <ComboboxPillsWarning
            label={t('Settings.variables.addVariables') as string}
            options={variables
              .map((variable) => ({ name: variable.id, inSection: !!variable.parentId }))
              .filter((v) => !chosenVariables.map((cv) => cv.id)?.includes(v.name))}
            value={chosenVariables.map((variable) => ({ name: variable.id, inSection: !!variable.parentId }))}
            onChange={(value) => {
              setChosenVariables(
                value.map((variable) => {
                  const v = variables.find((v) => v.id === variable.name)
                  return {
                    id: v?.id,
                    type: v?.type,
                    parentId: v?.parentId,
                  }
                }),
              )
            }}
          />
        </div>
        {!newVariables && (
          <p className='mt-2 flex items-start gap-x-3 text-gray-500 text-sm'>
            <ExclamationCircleIcon className='w-5 h-5 text-[#FF9B25] flex-none' />
            {t('Settings.variables.variableNotification')}
          </p>
        )}

        <div className='flex items-center justify-end gap-x-3 mt-6'>
          <Button className='hover:bg-gray-100 active:text-blue-500' onClick={() => onCancel?.()}>
            {t('Common.cancel')}
          </Button>
          <Button
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            onClick={() => onCreate?.(value, chosenVariables)}
            disabled={chosenVariables.length === 0 || (!value && !newVariables)}
            loading={creating}
          >
            {newVariables ? t('Common.add') : name ? t('Common.save') : t('Common.create')}
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default NewSectionModal
