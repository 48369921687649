import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { SelectOption } from '../UserTaskDetails'
import { Input, Option, Select, SortIcon, SortIconFlipped } from '@sistemiv/s-components'
import classNames from '../../../classNames'
import debounce from 'lodash/debounce'
import { useResponsive } from '../../../context/ResponsiveContextProvider'
import { Menu, Transition } from '@headlessui/react'

export type Assignee = {
  name: string
  id: string
  photoUrl: string
}

type TaskFilterProps = {
  sortOptions: SelectOption[]
  sortValue: SelectOption
  onSortValueChange?: (value: SelectOption) => void
  searchValue?: string
  onSearch: (value: string) => void
  sortOrder: boolean
  onSortOrderChange?: (order: boolean) => void
}

const TaskFilter: FC<TaskFilterProps> = ({
  searchValue,
  onSearch,
  onSortValueChange,
  onSortOrderChange,
  sortOptions,
  sortValue,
  sortOrder,
}) => {
  const [value, setValue] = useState(searchValue ?? '')
  const { isMobile } = useResponsive()
  const handleOnChange = (e) => {
    setValue(e.target.value)
    debouncedHandleSearch(e.target.value)
  }
  // eslint-disable-next-line
  const debouncedHandleSearch = useCallback(debounce(onSearch, 300), [])

  useEffect(() => {
    searchValue && setValue(searchValue)
  }, [searchValue])

  return (
    <div
      className={classNames(
        'flex flex-col justify-start items-center w-full gap-1',
        isMobile ? 'divide-y divide-gray-300' : '',
      )}
      data-testid='task-filter-wrap'
    >
      {isMobile ? (
        <div className='w-full flex items-center px-3'>
          <Input
            id='search'
            type='search'
            placeholder='Search...'
            value={value}
            onChange={handleOnChange}
            classes2='!bg-gray-100'
          />
        </div>
      ) : (
        <Input
          id='search'
          type='search'
          placeholder='Search'
          value={value}
          onChange={(e) => handleOnChange(e)}
          classes2='!border !border-solid !border-gray-300'
        />
      )}

      <div className='flex justify-start gap-x-4 items-center w-full pt-3'>
        {isMobile ? (
          <Menu as='div' className='relative'>
            <Menu.Button>
              <span className='text-gray-600 pl-3 my-3'>{sortValue.label}</span>
            </Menu.Button>
            <Menu.Items className='md:hidden'>
              <Menu.Item>
                {({ close }) => <div className='fixed inset-0 bg-black opacity-40 z-10' onClick={() => close()}></div>}
              </Menu.Item>
            </Menu.Items>
            <Transition
              as={Fragment}
              enter={isMobile ? 'transition-transform duration-80 ease-out' : ''}
              enterFrom={isMobile ? 'transform translate-y-full' : ''}
              enterTo={isMobile ? 'transform translate-y-0' : ''}
              leave={isMobile ? 'transition-transform duration-80 ease-in' : ''}
              leaveFrom={isMobile ? 'transform translate-y-0' : ''}
              leaveTo={isMobile ? 'transform translate-y-full' : ''}
            >
              <Menu.Items className='md:hidden fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg z-20 py-4 px-4 gap-y-1'>
                <div className='md:hidden w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
                {sortOptions.map((option) => (
                  <Menu.Item data-testid='task-filter-option' key={option.value}>
                    {() => (
                      <div
                        onClick={() => onSortValueChange && onSortValueChange(option)}
                        className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0 flex items-center justify-between'
                        data-testid='task-filter-option'
                      >
                        {/* Label on the left */}
                        <span data-testid='task-filter-sort'>{option.label}</span>

                        {/* Icon on the right */}
                        <span className='ml-5'>
                          {option.value === sortValue.value ? (
                            sortOrder ? (
                              <SortIconFlipped className={classNames('w-4 h-4 fill-gray-400')} />
                            ) : (
                              <SortIcon className={classNames('w-4 h-4 fill-gray-400')} />
                            )
                          ) : null}
                        </span>
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <Select
            value={sortValue}
            onChange={(e) =>
              onSortValueChange && onSortValueChange(sortOptions.find((o) => o.value === e) ?? sortOptions[0])
            }
          >
            {sortOptions.map((option) => (
              <div data-testid='task-filter-option' key={option.value}>
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              </div>
            ))}
          </Select>
        )}

        <button onClick={() => onSortOrderChange && onSortOrderChange(!sortOrder)} data-testid='task-filter-sort'>
          {sortOrder ? (
            <SortIconFlipped className={classNames('w-4 h-4 fill-gray-400')} />
          ) : (
            <SortIcon className={classNames('w-4 h-4 fill-gray-400')} />
          )}
        </button>
      </div>
    </div>
  )
}

export default TaskFilter
