import { PlusIcon } from '@heroicons/react/24/outline'
import { Button } from '@sistemiv/s-components'
import React, { useMemo, useState } from 'react'
import hamburgerIcon from '../../assets/icons/hamburger-icon.svg'
import SimpleWidget from '../home/SimpleWidget'
import WidgetWrapper from '../home/WidgetWrapper'
import ItemListWidget from '../home/ItemListWidget'
import SuccessWidget from '../home/SuccessWidget'
import { useCurrentProcessDefinition } from '../../repositories/process-instances/process-definition-selected.repository'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useProcessInstances } from '../../repositories'
import { useProcessTimeSeries } from '../../repositories/analytics/process-time-series.repository'
import dayjs from 'dayjs'
import TimeSeriesSelect from '../home/TimeSeriesSelect'
import TimeSeriesChart from '../charts/TimeSeriesChart'
import CreateProcessInstanceModal from '../process-instances/create-process-instance/CreateProcessInstanceModal'

const ProcessInstanceDashboard = () => {
  const { org, type } = useParams()
  const navigate = useNavigate()
  const [selectedTimeFilter, setSelectedTimeFilter] = useState('7')
  const now = useMemo(() => dayjs(), [])
  const [newInstanceOpen, setNewInstanceOpen] = useState(false)

  const { data: process } = useCurrentProcessDefinition({ org, processDefinitionKey: type })
  const { data: processInstances } = useProcessInstances({
    page: 1,
    size: 8,
    params: {
      filter: ['resolution:eql=null'],
      order: ['dueDate:asc'],
    },
  })
  const { data: processTimeSeries } = useProcessTimeSeries({
    organization: org,
    processDefinitionKey: process?.processDefinitionKey,
    startDate: now.subtract(parseInt(selectedTimeFilter), 'days').toDate(),
    endDate: now.toDate(),
  })
  const { t } = useTranslation()

  return (
    <div className='h-full px-6 bg-slate-200'>
      <header className='flex items-center justify-between py-10'>
        <h1 className='text-3xl'>{process?.name}</h1>
        <div className='flex gap-x-10'>
          <Button className='border border-sky-500 text-sky-500 !rounded-md' onClick={() => setNewInstanceOpen(true)}>
            <PlusIcon className='w-4 h-4' />
            {t('Dashboard.create')}
          </Button>
          <TimeSeriesSelect selectedTimeFilter={selectedTimeFilter} setSelectedTimeFilter={setSelectedTimeFilter} />
          <Button className='!p-0' onClick={() => navigate(`/${org}/process-instance/${type}`)}>
            <img className='cursor-pointer' src={hamburgerIcon} />
          </Button>
        </div>
      </header>
      <div className='grid grid-cols-2 gap-x-12'>
        {!!processInstances?.total && (
          <div className='space-y-[1px] w-full'>
            <WidgetWrapper className='bg-white rounded-b-none mt-1'>
              <div className='grid grid-cols-2 gap-x-8 text-slate-500 mt-1'>
                <SimpleWidget
                  className='py-0'
                  label={`${t('Dashboard.completed')} ${process?.name?.toLowerCase()}`}
                  value={processTimeSeries?.completedCount ?? 0}
                  trend={processTimeSeries?.completedChangeRate}
                />

                <SimpleWidget
                  className='py-0'
                  label={`${t('Dashboard.total')} ${process?.name?.toLowerCase()}`}
                  value={processTimeSeries?.totalCount ?? 0}
                  trend={processTimeSeries?.totalChangeRate}
                />
              </div>
            </WidgetWrapper>
            <WidgetWrapper className='bg-white rounded-t-none w-full'>
              <TimeSeriesChart
                data={{
                  labels: undefined,
                  datasets: [
                    {
                      data:
                        processTimeSeries?.timeSeries?.map((e) => ({
                          x: e.dateTime,
                          y: e.completed,
                        })) ?? [],
                      label: t('Dashboard.completed') ?? '',
                      fill: false,
                      borderColor: 'rgba(168, 149, 80, 1)',
                      tension: 0.5,
                      pointRadius: processTimeSeries?.timeSeries?.length > 1 ? 0 : 5,
                    },
                    {
                      data:
                        processTimeSeries?.timeSeries?.map((e) => ({
                          x: e.dateTime,
                          y: e.total,
                        })) ?? [],
                      label: t('Dashboard.total') ?? '',
                      fill: false,
                      borderColor: 'rgba(46, 119, 229, 1)',
                      tension: 0.5,
                      pointRadius: processTimeSeries?.timeSeries?.length > 1 ? 0 : 5,
                    },
                  ],
                }}
              />
            </WidgetWrapper>
          </div>
        )}

        <div>
          {!processInstances?.total ? (
            <SuccessWidget
              title={`${t('Dashboard.open')} ${process?.name?.toLowerCase()}`}
              message={`${t('Dashboard.noOpen')} ${process?.name?.toLowerCase()}`}
            />
          ) : (
            <div className='flex flex-col gap-y-[1px]'>
              <WidgetWrapper className='bg-white rounded-b-none mt-1'>
                <div className='grid grid-cols-2 gap-x-8 text-slate-500 mt-1'>
                  <SimpleWidget
                    className='py-0'
                    label={`${t('Dashboard.open')} ${process?.name?.toLowerCase()}`}
                    value={processInstances?.total ?? 0}
                  />
                  <SimpleWidget
                    className='py-0'
                    label={`${t('Dashboard.overdue')} ${process?.name?.toLowerCase()}`}
                    value={processTimeSeries?.overdueCount ?? 0}
                    trend={processTimeSeries?.overdueChangeRate}
                  />
                </div>
              </WidgetWrapper>
              <WidgetWrapper className='bg-white rounded-t-none'>
                <ItemListWidget
                  items={
                    processInstances?.results?.map((instance) => ({
                      id: instance.id,
                      processInstanceCode: instance.code
                        ? instance.code
                        : instance.processInstanceCode
                        ? instance.processInstanceCode
                        : '',
                      processDefinitionColor: process?.color,
                      dueDate: instance.dueDate,
                      title: instance.name,
                      name: instance.activities.at(0)?.activityName,
                    })) ?? []
                  }
                  title={`${t('Dashboard.open')} ${process?.name?.toLowerCase()}`}
                  onSeeMoreClick={() => navigate(`/${org}/process-instance/${type}`)}
                  onItemClick={(id) => navigate(`/${org}/process-instance/${type}/${id}`)}
                  processes
                />
              </WidgetWrapper>
            </div>
          )}
        </div>
      </div>
      {newInstanceOpen && <CreateProcessInstanceModal open={newInstanceOpen} setOpen={setNewInstanceOpen} />}
    </div>
  )
}

export default ProcessInstanceDashboard
