import React, { memo, useState } from 'react'
import classNames from '../../classNames'
import TaskForm from './TaskForm'
// import TaskComments from './TaskComments'
import { DiagramPreview, SpinnerIcon } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { useResponsive } from '../../context/ResponsiveContextProvider'
export interface FormField {
  id: string
  label: string
  value: any
  type: string
  description?: string
  isReadOnly?: boolean
  remoteOptionsUrl?: any
  options?: SelectOption[]
  validation?: any
  fileInfo?: any
}

export interface SelectOption {
  value: any
  label: string
}

type Tab = {
  key: 'task' | 'diagram'
  value: string
}
interface UserTaskDetailsProps {
  diagram?: any
  selectedTask: any
  setTaskList: any
  isTaskLoading: boolean
}

const UserTaskDetails = memo(function UserTaskDetails(props: UserTaskDetailsProps) {
  const { diagram, selectedTask, setTaskList, isTaskLoading } = props
  const { t } = useTranslation()
  const tabs: Tab[] = [
    { key: 'task', value: t('UserTasks.task') },
    { key: 'diagram', value: t('UserTasks.diagram') },
    //t('UserTasks.comments'),
  ]
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0])
  const { isMobile } = useResponsive()

  if (isTaskLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )

  return (
    <div className={classNames('w-full h-screen overflow-auto', isMobile ? 'px-4 py-2' : 'px-8 py-4')}>
      <div className='relative flex justify-start gap-x-8 text-slate-600'>
        <div className='absolute bottom-0 w-full h-0.5 z-0 bg-gray-300' />
        {tabs.map((tab, index) => (
          <div
            key={`tab-${index}`}
            onClick={() => setActiveTab(tab)}
            className={classNames(
              'relative z-2 py-3 cursor-pointer border-b-2 border-gray-300',
              isMobile ? 'text-sm font-medium py-2' : 'py-3',
              activeTab.key === tab.key ? 'border-blue-500 text-blue-500' : '',
            )}
          >
            {tab.value}
          </div>
        ))}
      </div>
      <div className={classNames('h-auto', isMobile ? 'mt-2' : 'mt-6')}>
        {activeTab.key === 'task' ? (
          <TaskForm selectedTask={selectedTask} setTaskList={setTaskList} />
        ) : // ) : activeTab === t('UserTasks.comments') ? (
        //   <TaskComments />
        activeTab.key === 'diagram' ? (
          selectedTask &&
          diagram && (
            <div className='h-full'>
              <DiagramPreview
                diagramXML={diagram.bpmn20Xml}
                highlightClass={'highlight'}
                activities={diagram.currentActivities}
                incidents={[]}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  )
})

export default UserTaskDetails
